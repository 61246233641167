<template>
  <el-card v-loading="loading" class="card__layout mb-4" :baselayout="loading">
    <el-row class="bg-white" :gutter="20">
      <el-col :md="8" :span="24">
        <el-col :span="24" class="mb-4">
          <apexchart
            v-if="optionsDonut.labels.length > 0"
            :options="optionsDonut"
            :series="seriesDonut"
            width="100%"
            type="donut"
          ></apexchart>
        </el-col>
        <el-col :span="24">
          <apexchart
            v-if="seriesBar[0].data.length > 0"
            :options="optionsBar"
            :series="seriesBar"
            width="100%"
            type="bar"
          ></apexchart>
        </el-col>
      </el-col>
      <el-col :md="16" :span="24">
        <apexchart
          v-if="seriesTreemap[0].data.length > 0"
          :options="optionsTreemap"
          :series="seriesTreemap"
          width="100%"
          type="treemap"
        ></apexchart>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import UserService from "@/services/user.service"

export default {
  name: 'Grafics',
  data() {
    return {
      loading: false,
      departmentFormated: [],
      seriesDonut: [],
      optionsDonut: {
        title: {
          text: '',
          align: 'center',
          margin: 5,
          offsetX: 0,
          offsetY: -5,
          style: {
            fontSize:  '16px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#515151',
          }
        },
        chart: {
          width: 380,
        },
        colors: ['#008FFB', '#FF4B4B'],
        labels: [],
      },
      seriesBar: [{
        name: 'total de usuários',
        data: []
      }] ,
      optionsBar: {
        title: {
          text: 'Unidades com maiores quant. de usuários cadastrados',
          style: {
            fontSize:  '16px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#515151',
          }
        },
        xaxis: {
          categories: []
        }
      },
      seriesTreemap: [{
        data: []
      }],
      optionsTreemap: {
        legend: {
          show: false
        },
        chart: {
          height: 700,
          type: 'treemap'
        },
        title: {
          text: 'Quantidade de usuários ativos com permissão SsJD por unidade',
          align: 'center',
          style: {
            fontSize:  '16px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#515151',
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
          },
          formatter: function(text, op) {
            return [text, op.value]
          },
          offsetY: -4
        },
        plotOptions: {
          treemap: {
            enableShades: true,
            shadeIntensity: 0.1,
            reverseNegativeShade: true,
            colorScale: {
              ranges: [
                {
                  from: 10,
                  to: 30,
                  color: '#ef4444'
                },
                {
                  from: 6,
                  to: 9,
                  color: '#fde047'
                },
                {
                  from: 0,
                  to: 5,
                  color: '#22c55e'
                }
              ]
            }
          }
        }
      },
    }
  },
  created() {
    this.userService = new UserService()
  },
  async mounted() {
    await this.countUsers()
  },
  methods: {
    async countUsers() {
      this.loading = true
      await this.userService
        .countUsers()
        .then(async ({total, active, department, ssjd}) => {
          const inactive = total - active
          this.seriesDonut = [active, inactive]
          this.optionsDonut.labels = [`Ativos - ${active} usuários`, `Inativos - ${inactive} usuários`] 
          this.optionsDonut.title.text = `Total de usuários: ${total}`
          this.departmentFormated = department
          this.optionsBar.xaxis.categories = department.map( dep => dep.sigla)
          this.seriesBar[0].data = department.map( dep => Number(dep.countDepartment))
          this.seriesTreemap[0].data = ssjd.map((info) => {
            return { x: info.sigla, y: Number(info.countUserSsjd) }
          })
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => this.loading = false)
    },
  }
}
</script>
<style scoped>
[baselayout] {
  min-width: 500px;
  min-height: 80vh;
}
.card__layout {
  width: 100%;
}
</style>
