<template>
  <el-row class="p-4 bg-white">
    <el-col :span="24">
      <Grafics />
    </el-col>
  </el-row>
</template>

<script>
import Grafics from '@/components/apexchart/Grafics'

export default {
  name: 'Dashboard',
  components: {
    Grafics
  }

}
</script>
